




import { Component } from 'vue-property-decorator';
import { ApexOptions } from 'apexcharts';
import ChartBase from '../ChartBase';
import RadialChartData from './modelsRadial';

@Component
export default class ChartBar extends ChartBase {
  declare chartData: RadialChartData;

  options: ApexOptions = {};

  series: any | null = null;

  onInitOrUpdate(): void {
    console.log('init or update');
    this.options = {
      chart: {
        id: '',
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: this.chartData.label ? {
              offsetY: 0,
              fontSize: '25px',
              color: '#008ffb',
            } : {
              offsetY: 23,
              fontSize: '15px',
              color: '#7a7a7a',
            },
            value: this.chartData.label ? {
              offsetY: 10,
              fontSize: '15px',
              formatter: (x) => `${(100 * Math.round(x)) / 100}%`,
            } : {
              offsetY: -14,
              fontSize: '25px',
              formatter: (x) => `${(100 * Math.round(x)) / 100}`,
            },
          },
        },
      },
      labels: this.chartData.label ? [this.chartData.label] : ['%'],
    };

    if (!this.chartData.valueField || !this.chartData.targetField) return;

    this.series = this.queryResult
      // eslint-disable-next-line
      .map((x: any) => x[this.chartData.valueField] / x[this.chartData.targetField] * 100);
  }
}
